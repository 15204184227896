export const ACCESS_TOKEN_NAME = 'foto-token'
/**
 * Random variables used in the app
 */
export const LOGIN_ROUTE_PATH = ''
export const HOME_ROUTE_PATH = 'home'
export const ADMIN_ROUTE_PATH = 'admin'
export const REGISTERED_ROUTE_PATH = 'registered'
export const ABOUT_ROUTE_PATH = 'about'
export const SIGN_SELECT_ROUTE_PATH = 'sign-select'
export const SIGN_MODE_EMAIL_ROUTE_PATH = 'email-sign'
export const SIGN_MODE_MULTI_ROUTE_PATH = 'multi-sign'
export const SIGN_MODE_SINGLE_ROUTE_PATH = 'single-sign'
export const SIGN_MODE_SINGLE_SCAN_ROUTE_PATH = 'scan'

//Model paths
export const MODEL_ROUTE_PATH = 'model'
export const MODEL_WELCOME_ROUTE_PATH = 'welcome'
export const SIGN_ROUTE_PATH = 'sign'
export const SIGNUP_ROUTE_PATH = 'result'

export const ROUTE_PARAM_PROJECT_ID = 'projectId'
export const ROUTE_PARAM_PHOTO_ID = 'photoId'
export const ROUTE_PARAM_GUARDIAN = 'guardian'
export const ROUTE_PARAM_SESSION_ID = 'sessionId'
export const ROUTE_PARAM_SIGNATURE_REF = 'signatureRef'

export const MINOR_ROUTE_PATH = 'minor'
