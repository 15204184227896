import {Component, OnInit, ViewChild} from '@angular/core'
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {BankIdModule} from '@jhc/bankid'
import {first} from 'rxjs'
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject'
import {environment} from '../../../environments/environment'
import {
  MINOR_ROUTE_PATH,
  MODEL_ROUTE_PATH,
  MODEL_WELCOME_ROUTE_PATH,
  ROUTE_PARAM_SESSION_ID,
  SIGNUP_ROUTE_PATH
} from '../../application/constants'
import {IFileUpload} from '../../application/types'
import {
  IImageHandlerEvent,
  ImageHandlerComponent
} from '../../common/image-handler/image-handler.component'
import {FotoSessionService} from '../../services/foto-session.service'
import {PhotoService} from '../../services/photo.service'

@Component({
  selector: 'foa-model-signing-sign',
  templateUrl: './model-signing-sign.component.html',
  styleUrls: ['./model-signing-sign.component.scss'],
  standalone: true,
  imports: [MatButton, ReactiveFormsModule, ImageHandlerComponent, BankIdModule]
})
export class ModelSigningSignComponent implements OnInit {
  @ViewChild(ImageHandlerComponent, {static: false}) imageHandler?: ImageHandlerComponent

  public modelDescriptionCtrl: FormControl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.minLength(8), Validators.required]
  })

  public image = ''
  public uploadedImage: IFileUpload | null = null
  public sessionId = ''
  public display: boolean = false

  protected readonly environment = environment

  private webSocketSubject?: WebSocketSubject<any>

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private photoService: PhotoService,
    private fotoSessionService: FotoSessionService
  ) {
  }

  public ngOnInit() {
    this.webSocketSubject = this.fotoSessionService.createWebSocket('uffe')
    this.webSocketSubject?.subscribe()

    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        if (params.has(ROUTE_PARAM_SESSION_ID)) {
          this.sessionId = params.get(ROUTE_PARAM_SESSION_ID) as string
          // If the user manually goes to this page and sets a sessionId by
          // themselves we need to check that it's valid.
          this.fotoSessionService.session$
            .pipe(first())
            .subscribe((session) => {
              if (session?.sessionId === this.sessionId) {
                this.display = true
              } else {
                this.router.navigate([
                  '/', MODEL_ROUTE_PATH, MODEL_WELCOME_ROUTE_PATH, this.sessionId]).then()
              }
            })
        }
      }
    })

    this.modelDescriptionCtrl.valueChanges.subscribe({
      next: () => {
        this.photoService.modelDescription$.set(this.modelDescriptionCtrl.value)
      }
    })
  }

  public signCompleted(event: string) {
    this.photoService.signCompleted(event, this.sessionId, this.uploadedImage?.id, this.photoService.modelDescription$())
      .subscribe({
        next: (signatureRef: string) => {
          this.sendWebSocket()
          this.router.navigate(['/',
            MODEL_ROUTE_PATH, SIGNUP_ROUTE_PATH, signatureRef]).then()
        }
      })
  }

  public onImageCaptured(event: IImageHandlerEvent) {
    this.image = event.image
    this.uploadedImage = event.uploadedImage
  }

  public openCam() {
    this.imageHandler?.openCam()
  }

  public minorMoveOn() {
    const url = ['/', MINOR_ROUTE_PATH, MODEL_WELCOME_ROUTE_PATH,
      this.sessionId, '']
    if (this.uploadedImage?.id) {
      url.pop()
      url.push(this.uploadedImage.id)
    }
    this.router.navigate(url).then()
  }

  /**
   * Sends data via WebSocket when called.
   * Checks if the WebSocket connection is established before sending.
   */
  private sendWebSocket() {
    // Check if the WebSocket connection is established before sending data.
    if (this.webSocketSubject) {
      this.webSocketSubject.next({
        type: 'send',
        sessionId: 'uffe'
      })
    }
  }
}
