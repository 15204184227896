import {AsyncPipe} from '@angular/common'
import {Component, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {BankIdModule} from '@jhc/bankid'
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject'
import {environment} from '../../../environments/environment'
import {
  MINOR_ROUTE_PATH,
  MODEL_ROUTE_PATH,
  MODEL_WELCOME_ROUTE_PATH, ROUTE_PARAM_GUARDIAN,
  ROUTE_PARAM_PHOTO_ID,
  ROUTE_PARAM_SESSION_ID,
  SIGN_ROUTE_PATH,
  SIGNUP_ROUTE_PATH
} from '../../application/constants'
import {
  ImageHandlerComponent
} from '../../common/image-handler/image-handler.component'
import {FotoSessionService} from '../../services/foto-session.service'
import {PhotoService} from '../../services/photo.service'

@Component({
  selector: 'foa-model-signing-welcome',
  standalone: true,
  imports: [
    AsyncPipe,
    MatIcon,
    MatButton,
    ImageHandlerComponent,
    BankIdModule
  ],
  templateUrl: './model-signing-welcome.component.html',
  styleUrls: ['./model-signing-welcome.component.scss']
})
export class ModelSigningWelcomeComponent implements OnInit {

  public sessionId = ''
  public photoId?: string
  public image: string = ''
  public display: boolean = false
  public sessionState: boolean = false
  public guardian: boolean = false

  protected readonly environment = environment

  private webSocketSubject?: WebSocketSubject<any>

  constructor(
    protected fotoSessionService: FotoSessionService,
    private photoService: PhotoService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit() {
    this.webSocketSubject = this.fotoSessionService.createWebSocket('uffe')
    this.webSocketSubject?.subscribe()

    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        if (params.has(ROUTE_PARAM_SESSION_ID)) {
          this.sessionId = params.get(ROUTE_PARAM_SESSION_ID)!
        }
      }
    })

    this.route.queryParamMap.subscribe({
      next: (params: ParamMap) => {
        if (params.has(ROUTE_PARAM_PHOTO_ID)) {
          this.photoId = params.get(ROUTE_PARAM_PHOTO_ID)!
        }
        if (params.has(ROUTE_PARAM_GUARDIAN)) {
          this.guardian = true
        }
      }
    })

    this.fotoSessionService.getSession(this.sessionId)
      .subscribe({
        next: (session) => {
          if (session) {
            this.display = true
            this.sessionState = true
            if (session.user.photoUrl) {
              this.image = session.user.photoUrl
            }
          }
        },
        error: () => {
          this.display = true
        }
      })
  }

  public moveOn() {
    this.router.navigate(['/', MODEL_ROUTE_PATH, SIGN_ROUTE_PATH,
      this.sessionId]).then()
  }

  public minorMoveOn() {
    this.router.navigate(['/', MINOR_ROUTE_PATH, MODEL_WELCOME_ROUTE_PATH,
      this.sessionId, this.photoId]).then()
  }

  public onSignWithPhotoFromSingleSign(event: string) {
    this.photoService.signCompleted(event, this.sessionId, this.photoId)
      .subscribe({
        next: (signatureRef: string) => {
          this.sendWebSocket()
          this.router.navigate(['/',
            MODEL_ROUTE_PATH, SIGNUP_ROUTE_PATH, signatureRef]).then()
        }
      })
  }

  /**
   * Sends data via WebSocket when called.
   * Checks if the WebSocket connection is established before sending.
   */
  private sendWebSocket() {
    // Check if the WebSocket connection is established before sending data.
    if (this.webSocketSubject) {
      this.webSocketSubject.next({
        type: 'send',
        sessionId: 'uffe'
      })
    }
  }
}
