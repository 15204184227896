import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes'
import {NgForOf} from '@angular/common'
import {Component, OnInit} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {
  MatChip,
  MatChipGrid,
  MatChipInput,
  MatChipInputEvent,
  MatChipsModule
} from '@angular/material/chips'
import {MatIcon} from '@angular/material/icon'
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {BankIdModule} from '@jhc/bankid'
import {forkJoin, switchMap} from 'rxjs'
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject'
import {environment} from '../../../environments/environment'
import {
  MODEL_ROUTE_PATH,
  ROUTE_PARAM_PHOTO_ID,
  ROUTE_PARAM_SESSION_ID,
  SIGNUP_ROUTE_PATH
} from '../../application/constants'
import {IConsentItem, IKidModel, IMinorSignModel} from '../../application/types'
import {LoaderComponent} from '../../common/loader/loader.component'
import {EmailService} from '../../services/email.service'
import {FotoSessionService} from '../../services/foto-session.service'
import {PhotoService} from '../../services/photo.service'
import {pnrValidator} from '../../validators/personal-number-validator'

@Component({
  selector: 'foa-model-signing-minor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgForOf,
    MatChipGrid,
    MatChip,
    MatIcon,
    MatChipInput,
    MatChipsModule,
    LoaderComponent,
    MatTooltip,
    MatTooltipModule,
    BankIdModule,
    MatButton
  ],
  templateUrl: './model-signing-minor.component.html',
  styleUrls: ['./model-signing-minor.component.scss']
})
export class ModelSigningMinorComponent implements OnInit {

  public recipients: IMinorSignModel[] = []
  public kids: IKidModel[] = []
  public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON]
  public sessionId = ''
  public photoId?: string
  public image: string = ''
  public display: boolean = false
  public sessionState: boolean = false

  public form = new FormGroup({
    pNr: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, pnrValidator]
    }),
    recipient: new FormControl('', Validators.email),
    message: new FormControl('', {
      nonNullable: true
    })
  })

  private webSocketSubject?: WebSocketSubject<any>

  constructor(
    protected photoService: PhotoService,
    protected fotoSessionService: FotoSessionService,
    protected emailService: EmailService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  public ngOnInit() {
    this.webSocketSubject = this.fotoSessionService.createWebSocket('uffe')
    this.webSocketSubject?.subscribe()

    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        if (params.has(ROUTE_PARAM_SESSION_ID)) {
          this.sessionId = params.get(ROUTE_PARAM_SESSION_ID)!
        }
        if (params.has(ROUTE_PARAM_PHOTO_ID)) {
          this.photoId = params.get(ROUTE_PARAM_PHOTO_ID)!
        }
      }
    })

    this.fotoSessionService.getSession(this.sessionId)
      .subscribe({
        next: (session) => {
          if (session) {
            this.display = true
            this.sessionState = true
            if (session.user.photoUrl) {
              this.image = session.user.photoUrl
            }
          }
        },
        error: () => {
          this.display = true
        }
      })
  }

  public addRecipient(event: MatChipInputEvent): void {
    const value = event.value.trim()

    // Make sure that control's value is trimmed before its validity check
    this.form.controls.recipient.setValue(value)

    if (this.form.controls.recipient.valid && event.value) {
      // Remove value from recipient's input once we know it's valid
      this.form.controls.recipient.setValue(null)

      this.recipients.push({guardianEmail: value})
      event.chipInput.clear()
      this.form.updateValueAndValidity()
    }
  }

  public removeRecipient(recipient: string): void {
    const index = this.recipients.findIndex((r: IMinorSignModel) =>
      r.guardianEmail === recipient)
    this.recipients.splice(index, 1)
    this.form.updateValueAndValidity()
  }

  public signCompleted(event: string) {
    let signatureRef = ''
    this.fotoSessionService.addKidToSession(this.sessionId, this.form.controls.pNr.value).pipe(
      switchMap(() => this.photoService.signCompleted(event, this.sessionId, this.photoId, this.photoService.modelDescription$())),
      switchMap((signRef: string) => {
        signatureRef = signRef
        return this.photoService.getConsentById(signRef)
      }),
      switchMap((consent: IConsentItem) => {
        return forkJoin([
          this.emailService.sendGuardianSignEmail(
            consent.projectId!,
            this.sessionId,
            this.photoId!,
            this.recipients,
            'Signering minderårig',
            this.form.controls.message.value,
            consent.modelName!
          )
        ])
      })
    ).subscribe({
      next: () => {
        this.sendWebSocket()
        this.router.navigate(['/',
          MODEL_ROUTE_PATH, SIGNUP_ROUTE_PATH, signatureRef]).then()
      }
    })
  }

  private sendWebSocket() {
    // Check if the WebSocket connection is established before sending data.
    if (this.webSocketSubject) {
      this.webSocketSubject.next({
        type: 'send',
        sessionId: 'uffe'
      })
    }
  }

  protected readonly environment = environment
}
