import {AbstractControl, ValidationErrors} from '@angular/forms'
import {validatePersonnummerControl} from './personal-number-control-validator'

/**
 * Validates a personal identification number (personnummer) using our personnummer lib
 * If the provided value is a valid personal identification number, the function sets the form control's value
 * If the value is not a valid personal identification number, an error is returned with the error message.
 *
 * @param control - The form control to be validated.
 * @returns A ValidationErrors object if the value is invalid, otherwise null.
 */
export const pnrValidator = (control: AbstractControl): ValidationErrors | null => {
  return validatePersonnummerControl(control, false, [])
}
