import {AbstractControl} from '@angular/forms'
import {PersonNummer, verify} from '@jhc/personnummer'
import {IPnrValidationErrors} from './personal-number-validator-extended'

export const DEFAULT_ERROR_MESSAGE: string = 'Vänligen kontrollera så att personnumret stämmer'
export const DUPLICATED_ERROR_MESSAGE: string = 'Personnumret kan inte användas två gånger'
export const UNDERAGE_ERROR_MESSAGE: string = 'Du måste vara över 18 år för att få ansöka om lån i Sparbanken Syd'

export const validatePersonnummerControl = (
  control: AbstractControl,
  mustBeOver18: boolean,
  otherPersonnummers: (string | undefined)[]
): IPnrValidationErrors | null => {
  if (control.value === '' || control.value === null) {
    return null
  }

  // If Personnummer is less than 10 characters there is no need to check it
  if (control.value.length < 10) {
    return {error: '', isDuplicated: false, isUnderage: false}
  }

  // Check duplicated Personnummers (if any to check)
  if (otherPersonnummers.some((otherPersonnummer: string | undefined) =>
    otherPersonnummer?.replace('-', '') === control.value.replace('-', ''))) {
    return {error: DUPLICATED_ERROR_MESSAGE, isDuplicated: true, isUnderage: false}
  }

  try {
    const response: PersonNummer = verify(control.value)

    // Set the form control's value to the validated value only if it's different
    if (control.value !== response['12']) {
      control.setValue(response['12'], {onlySelf: true, emitEvent: false})
    }

    // Check +18 if necessary
    if (mustBeOver18 && response.age < 18) {
      return {error: UNDERAGE_ERROR_MESSAGE, isDuplicated: false, isUnderage: true}
    }

    // All OK, no errors.
    return null
  } catch (err) {
    console.error(err)
    return {error: DEFAULT_ERROR_MESSAGE, isDuplicated: false, isUnderage: false}
  }
}
